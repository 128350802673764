import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import timeStampParam from "../helpers/api";
import { Generation } from "../types";
import { useInView } from "framer-motion";
import GenerationCard from "./GenerationCard";


type GenerationsSectionProps = {
  isFnacUserOrDemo?: boolean
}

const GenerationsSection = (
  { isFnacUserOrDemo }: GenerationsSectionProps
) => {
  const { keycloak } = useKeycloak();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [generations, setGenerations] = useState<Generation[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();
  // const [userName, setUserName] = useState("");
  const scrollLoaderRef = useRef<HTMLDivElement>(null);
  const useInview = useInView(scrollLoaderRef);

  let apiURL = `${import.meta.env.VITE_API_URL}/generation`;

  // XXX temporary: use the legacy endpoint returning s3 presigned URLs for Fnac event
  // Can be removed afterward.
  if (isFnacUserOrDemo) {
    apiURL = `${import.meta.env.VITE_API_URL}/my-generations`;
  }

  apiURL += `${timeStampParam}&page=${page}&order"desc"&sorter="date"`

  useEffect(() => {
    if (!useInview) return;

    setPage((prev) => prev + 1);
  }, [useInview]);


  useEffect(() => {
    if (!keycloak.token) return;

    setIsLoading(true);

    fetch(apiURL,
      {
        headers: { authorization: `Bearer ${keycloak.token}` },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return setIsError(true);

        setGenerations((prev) => [
          ...prev,
          ...data.generations,
        ]);

        setTotalPage(data.totalPages);
      })
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false));
  }, [keycloak, page]);

  // useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   const getUser = async () => (await keycloak.loadUserInfo()) as any;
  //   getUser().then((userInfo) => {
  //     setUserName(userInfo.given_name)
  //   });
  // }, [keycloak]);


  return (
    <>

      <h2 className="font-extrabold text-2xl md:text-3xl mb-8 uppercase">
        {t("Generations.Title")}
      </h2>

      {isError && <p className="text-red mb-3">{t("Generations.Error")}</p>}

      {generations.length === 0 && (
        <p>
          {t("Generations.NoGenerations")}
          <br />
          <a href="/creator" className="text-primary not-italic underline">
            {t("Generations.BtnStart")}
          </a>
        </p>
      )}

      <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {generations.map((generation, index) => (
          <GenerationCard generation={generation} key={index} isFnacUserOrDemo={isFnacUserOrDemo} />
        ))}
      </div>

      <div className="" ref={scrollLoaderRef}></div>

      {page < totalPage && (
        <button
          className="p-2 border rounded-md mt-4 block mx-auto hover:bg-primary hover:text-grey"
          onClick={() => setPage((prev) => prev + 1)}
          disabled={isLoading}
        >
          {t("Generations.LoadMore")}
        </button>
      )}
    </>
  );
};

export default GenerationsSection;

import { LucideIcon } from "lucide-react";

export enum BeinkMode {
  Imagine = "Imagine",
  Inpaint = "Inpaint",
}

export enum DesignStyle {
  Industrial = "Industrial",
  Line = "Line",
  Davinci = "Davinci",
  HandSketch = "HandSketch",
  FashionDrawing = "FashionDrawing",
  Scientific = "Scientific",
  Photorealistic = "Photorealistic",
}


export type Submission = {
  id: string;
  src: string;
  author: string;
  date: Date;
  likes: number;
  votes: Record<string, string>[];
};

export type Card = {
  id: string;
  src: string;
  author: string;
  date: string;
  likes: number;
  hasMyVote: boolean;
};

export type Order = "asc" | "desc";

export type Sorter = "date" | "like";
export type Mode = "normal" | "moderate";

export type DataAiFromApi = {
  id: string;
  prompt: string;
  results: null | string;
  user_created: string;
  date_created: string;
  status: string;
  progress: number | null;
  url: null | string;
  error: null | string;
  upscaled_urls: null | string[];
  urls: null | string[];
  upscaled: string[];

  generationsLeft: number;
};

export enum BeinkPhase {
  Generate = 1,
  Results = 2,
  Help = 3,
}

export type GenerationDTO = {
  genId: string;
  date: string;
  images: string[];
};

export type SelectedGenerationDTO = {
  genId: string;
  date: string;
  img: string;
};

export type Proposals = string[];

export type Tools =
  | "pen"
  | "select"
  | "hand"
  | "text"
  | "eyedropper"
  | "crop"
  | "eraser";

export type ToolsList = {
  name: Tools;
  title?: string;
  onClick?: () => void;
  icon: LucideIcon;
};
export type Shortcuts = {
  keys: string[];
  name: string;
  icon: LucideIcon;
};

export enum PenSize {
  sm = 2,
  md = 5,
  lg = 10,
  xlg = 20,
  xxlg = 30,
}

export enum EraserSize {
  sm = 2,
  md = 5,
  lg = 10,
  xlg = 20,
  xxlg = 30,
}

export type Generation = {
  id: string;
  input: string;
  prompt: string;
  graphicStyle: string;
  scribblePath: string;
  whiteboard: null;
  userId: string;
  ai: string;
  error: null;
  status: string;
  createdAt: string;
  generatedImages: { imagePath: string; generationId: string }[];
  generatedImagesUrl: string[];
  scribbleUrl: string;
};

export enum UserRole {
  StaffDemo = "beink-staff-demo",
  VIP = "beink-users-vip",
  Free = "beink-users-free",
  Subscriber_1 = "beink-users-subscription-level-1",
  TeamManager = "beink-users-team-manager",
  Inpaint = "beink-users-inpaint",
}

export type Offer = {
  name: string;
  desc: string;
  price: number;
  details: string[];
  clientSecret?: string;
};

export type Member = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string[];
};

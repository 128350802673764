import { faClock } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Generation } from "../types"
import { motion } from "framer-motion"
import { faX } from "@fortawesome/free-solid-svg-icons"
import GenerationImage from "./GenerationImage"

type GenerationInfoPanelProps = {
    generation: Generation
    timeSinceCreationString: string
    setIsInfoPanelOpen: (isOpen: boolean) => void
    isFnacUserOrDemo?:boolean
}

const GenerationInfoPanel = ({ generation, timeSinceCreationString, setIsInfoPanelOpen, isFnacUserOrDemo }: GenerationInfoPanelProps) => {
    const { generatedImagesUrl } = generation;

    return (

        <div className="fixed inset-0 flex h-dvh z-100 w-screen items-center justify-end bg-grey-700 bg-opacity-25 backdrop-blur-md">

            <motion.div className="mx-2 flex w-full flex-col gap-6 rounded-xl bg-grey-600 p-6 sm:mr-6 sm:w-[600px] h-[98vh] overflow-auto"
                initial={{ x: "100%" }}
                animate={{ x: 0 }}
                exit={{ x: "100%" }}
                transition={{ ease: "easeOut", duration: 0.2 }}
            >

                <div className="images grid grid-cols-2 gap-6">
                    {generatedImagesUrl.map((url, index) => {
                        if (isFnacUserOrDemo) return (
                            // XXX temporary: load image with s3 presigned URL for Fnac event
                            <a key={index} className="w-full aspect-[16/11]" href={url} target="blank">
                                <img className="rounded-xl w-full h-full object-cover cursor-pointer" key={index} src={url} alt="" />
                            </a>
                        )
                        
                        return <GenerationImage imagePath={url} className="rounded-xl w-full h-full object-cover cursor-pointer" key={index} alt="" linkClassName="w-full aspect-[16/11]" withLink={true}  />;
                    })}
                </div>

                <div className="flex flex-col gap-6 flex-grow justify-between">
                    <div>
                        <div className="items-center gap-2 hidden">
                            <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                            <p className="text-sm text-white">{timeSinceCreationString}</p>
                        </div>
                        <div className="prompt font-normal bg-fnac-card-default p-4 rounded-2xl">
                            <p className="text-white text-xl font-ccsign">{generation.input}</p>
                        </div>
                    </div>

                    <motion.div whileHover={{ rotate: "180deg" }}
                        className="m-5 flex h-[44px] w-[44px] cursor-pointer items-center justify-center self-center rounded-full bg-white text-fnac-black"
                        onClick={() => { setIsInfoPanelOpen(false) }}>
                        <FontAwesomeIcon className="text-black" icon={faX} ></FontAwesomeIcon>
                    </motion.div>
                </div>

            </motion.div>



        </div>
    )
}

export default GenerationInfoPanel

import { useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'

type GenerationImageProps = {
    imagePath: string
    id?: string
    className?: string
    alt?: string
    withLink?: boolean
    linkClassName?: string
}

// GenerationImage is a component displaying a generation image in an img tag.
// It uses bearer token to fetch the image from the API.
const GenerationImage = ({ 
        imagePath,
        id,
        className,
        alt,
        withLink,
        linkClassName
    }: GenerationImageProps) => {
    const { keycloak } = useKeycloak();
    const [imgUrl, setImgUrl] = useState<string>("");
    const apiUrl = import.meta.env.VITE_API_URL;
    
    // Fetch image from API with authent
    useEffect(() => {
        if (!keycloak) return;

        if (!imagePath.startsWith("/")) imagePath = "/" + imagePath;

        fetch(apiUrl + imagePath, {
            headers: {
            'Authorization': `Bearer ${keycloak.token}`,
            },
        })
        .then(response => response.blob())
        .then(blob => {
          setImgUrl(URL.createObjectURL(blob));
        });

        return () => {
            // Cleanup: revoke blob URL in order to relase it from browser memory
            URL.revokeObjectURL(imgUrl);
        }
      }, [apiUrl, imagePath]);
    
    if (!imgUrl) return;

    if (withLink) return (
            <a className={linkClassName} href={imgUrl} target="blank">
                <img id={id} className={className} src={imgUrl} alt={alt} />
            </a>
        )
    
    return <img id={id} className={className} src={imgUrl} alt={alt} />
}

export default GenerationImage
